$fluid-padding: 25%;
$fluid-padding-lg: 15%;
$navbar-bg: #202225;
$bg-color: #2f3136;
$card-bg: #36393f;
$footer-bg: $card-bg;
$font-color-primary: #fff;
$font-color-secondary: #999999;
// $border-primary: #03a9f4;
$border-primary: #f2872ecc;
$button-hover: rgb(255 116 0);
$glyph-primary: $border-primary;

.navbar {
	background-color: $navbar-bg;
	border-bottom: 2px solid $border-primary;
}

.card-border {
	color: rgba(255, 255, 255, 0.55);
	background: rgba(255, 255, 255, 0.1);
	height: 1px;
	width: 100%;
	margin: 30px 0;
	font-size: 12px;
	line-height: 20px;
	text-transform: uppercase;
	position: relative;
	span {
		position: absolute;
		background: $bg-color;
		top: -10px;
		padding: 0 10px;
		left: calc(50% - 1em - 5px);
	}
}

body {
	background-color: $bg-color;
	color: $font-color-primary;
}

.App {
	text-align: center;
}

html {
	scroll-behavior: smooth;
}

.jumbotron {
	background: url(/images/collage.jpg) repeat-y;
	background-blend-mode: overlay;
	animation: slide 60s linear infinite;
	box-shadow: inset 0 0 0 1000px rgba(44, 41, 41, 0.8);
	min-height: 600px;
	background-position: center;
	border-bottom: 1px solid $border-primary;
	border-radius: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 2rem;
}

.ml-auto {
	margin-left: auto;
}

@keyframes slide {
	0% {
		background-position-y: 0px;
	}
	100% {
		background-position-y: 1979px;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

#serverCount {
	animation: fadein 5s;
}

.bg-dark {
	background-color: $card-bg !important;
}

.Box-Shadow {
	box-shadow: 0px 4px 10px 2px rgba(41, 35, 34, 0.3);
	border-radius: 0;

	.card {
		border-radius: 0;
		background-color: $card-bg;
		color: $font-color-primary; //rgb(51, 51, 51);

		* {
			margin: 5px;
		}
	}

	.card-title {
		text-transform: uppercase;
		min-height: 48px;
		vertical-align: middle;
		display: flex;
		justify-content: center;
		align-content: center;
		flex-direction: column;
	}

	.card-text {
		font-weight: 400;
		color: rgb(153, 153, 153);
	}
}

// .info-panel:nth-child(odd) {
// 	background-color: $card-bg;//rgb(245, 250, 253);
.info-panel {
	padding-top: 40px;
	padding-bottom: 40px;

	.feature-card,
	* {
		margin-bottom: 5px;
		margin-top: 5px;
	}

	.image-shadow {
		box-shadow: 15px 25px 0px 3px $border-primary;
	}
}

.flex-col {
	vertical-align: middle !important;
	margin-top: auto;
	margin-bottom: auto;
	display: flex !important;
	flex-direction: column;
}

//HEADERS
h3 {
	font-weight: 300;
	font-size: 2.25em;
	line-height: 1.25;
	color: $font-color-primary;
}

h5 {
	font-weight: 600;
	font-size: 1.125em;
	line-height: 1.55;
	color: $font-color-primary;
	text-transform: uppercase;

	//All p tags after h5
	& + p {
		font-weight: 400;
		font-size: 16px;
		line-height: 1.6;
		color: rgb(153, 153, 153);
	}
}

.header-sub {
	font-weight: 300;
	font-size: 1.25em;
	line-height: 1.5;
	color: $font-color-secondary;
}

.btn-primary {
	background-color: $glyph-primary;
	border-color: $glyph-primary;
}

.btn-outline-primary {
	border-color: $glyph-primary;
	color: $glyph-primary;
}

.btn-outline-primary:hover {
	border-color: $glyph-primary;
	color: $font-color-primary;
	background-color: $glyph-primary;
}

//Need media queries to adjust, to ensure we dont use % padding for small sizes.
@media (min-width: 1200px) {
	nav:has(.container-fluid) {
		padding-left: $fluid-padding;
		padding-right: $fluid-padding;
	}
	.container-fluid {
		.card-group {
			margin-left: $fluid-padding;
			margin-right: $fluid-padding;
		}

		.info-panel {
			padding-left: $fluid-padding;
			padding-right: $fluid-padding;
		}
	}
}

@media (min-width: 992px) {
	nav:has(.container-fluid) {
		padding-left: $fluid-padding-lg;
		padding-right: $fluid-padding-lg;
	}
	.container-fluid {
		.card-group {
			margin-left: $fluid-padding-lg;
			margin-right: $fluid-padding-lg;
		}

		.info-panel {
			padding-left: $fluid-padding-lg;
			padding-right: $fluid-padding-lg;
		}
	}
}

.dashboard-page {
	.card {
		margin-top: 10px;
		margin-bottom: 10px;
		margin-right: 25px;
		margin-left: 25px;
	}

	.default-image {
		object-fit: contain;
		border-bottom: 1px solid rgba(0, 0, 0, 0.125);
	}
}

.nav-item {
	.rounded-circle {
		height: 28px;
		margin-right: 0.5rem;
	}
}

.dropdown-avatar {
	display: inline;
}

.error-message {
	h1 {
		color: $font-color-primary;
	}
}

.flex {
	display: flex;
}

.page-loader {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 250px;
	height: 250px;
	margin-left: -125px;
	margin-top: -125px;
}

.loading-text {
	position: absolute;
	top: calc(50% - 25px);
	text-align: center;
}

.card.active {
	border: 1px solid cyan;
}

.pointer {
	cursor: pointer;
}

//Movie comps
.card,
.movie {
	.movie-button-group {
		width: calc(100% + 1px);
		top: -38px;
		margin-bottom: -38px;
		display: none;

		button {
			border-radius: 0;
			width: 50%;
		}
	}

	.viewed-icon {
		margin: 10px;
		color: rgba(3, 169, 244, 0.3);
		position: absolute;
	}

	.premium-icon {
		margin: 10px;
		position: absolute;
		color: $button-hover;
		transition: color 0.5s linear;
		z-index: 3;
	}

	.card-body {
		min-height: 127px;
	}
}

.payment-dropdown {
	text-align: left !important;

	.dropdown-toggle::after {
		font-size: 1.5em !important;
		margin-bottom: -10px;
		vertical-align: middle !important;
	}
}

.hidden-toggle {
	display: none;
}

.hover-toggle {
	visibility: hidden;
	opacity: 0;
	transition: all 0.1s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
	top: 10%;
	text-align: center;
	position: absolute;
	width: 100%;
}

.list-group-item {
	background-color: transparent;
	color: $font-color-primary;
	font-weight: bold;
}

.menu-page,
.dashboard-page {
	//Footer+Navbar
	min-height: calc(100vh - 535px);

	.container:not(.container-footer),
	.container-fluid {
		//Footer+Navbar
		min-height: calc(100vh - 535px);
	}
}

.menu-page {
	.hover-toggle {
		height: 256px;
	}
}

.movie {
	background-color: $card-bg;
	border-radius: 3px;
	box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
	overflow: hidden;
	position: relative;
	margin: 1rem;
	margin-left: auto;
	margin-right: auto;
	width: 300px;

	.movie-details {
		text-align: center;
		padding: 10px;
		margin-bottom: 38px;

		h3 {
			font-size: 24px;

			a {
				color: $font-color-primary;
			}
		}

		.text-muted {
			color: rgba(255, 255, 255, 0.5) !important;
			margin-bottom: 0.5rem;
		}
	}

	.poster-container {
		min-height: 450px;
		border-bottom: 1px solid $border-primary;
	}

	.button-container {
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}

.card:hover,
.movie:hover {
	.viewed-icon {
		color: $button-hover;
		transition: color 0.5s linear;
		z-index: 3;
	}

	.movie-button-group {
		display: block;
	}

	.card-img-top,
	img {
		filter: grayscale(50%);
		filter: brightness(0.4);
		transition: filter 0.5s linear;
	}

	.hover-toggle {
		visibility: visible;
		opacity: 1;
		transition: all 0.5s ease-in-out 0s, visibility 0s linear 0s, z-index 0s linear 0.3s;
	}
}

.footer {
	background-color: $footer-bg; //rgb(75, 70, 70);
	margin-top: 20px;

	h3 {
		text-transform: uppercase;
		color: $font-color-primary;
		margin-bottom: 30px;
	}

	h5 {
		text-transform: none;
		color: $font-color-primary;
	}

	a {
		color: $font-color-primary;
	}

	a:hover {
		color: #4b4646;
	}

	.info {
		background-color: $border-primary;
		padding: 60px;
		margin-left: auto;
		margin-right: auto;
		width: 100%;
		text-align: center;
		border-radius: 5px;
		margin-top: 30px;
		margin-bottom: 30px;
		color: $font-color-primary;
	}

	svg {
		margin-left: 1em;
		margin-right: 1em;
		margin-bottom: 30px;
	}
}

.dropdown-menu {
	display: flex;
	flex-direction: column;
	visibility: hidden;
	transform: translateY(-1em);
	opacity: 0;
	z-index: 1;
	transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

	.dropdown-item {
		display: flex;

		.guild-name {
			flex-grow: 1;
		}

		.invite-icon {
			float: right;
			margin-left: 15px;
		}
	}
}

.dropdown-menu.show {
	visibility: visible;
	transform: translateY(0%);
	z-index: 4;
	opacity: 1;
	transition: all 0.3s ease-in-out 0s, visibility 0s linear 0s, z-index 0s linear 0.3s;
}

.dropdown-item.active,
.dropdown-item:active {
	background-color: $border-primary;
}

.btn-group {
	button,
	label {
		border-radius: 0px;
	}
}

button.btn-primary:hover,
a.btn-primary:hover {
	background-color: $button-hover;
	border-color: $border-primary;
}

.toast.show {
	background-color: #202225;
}

.commands-page {
	.col h5 {
		background-color: $border-primary;
	}

	.col .card {
		background-color: #1d1f2cd1;
	}
}

.feature-image {
	text-align: center;
	margin-top: auto;
	margin-bottom: auto;
	padding-bottom: 20px;
}

@media (max-width: 768px) {
	.card-group > .card {
		display: block;
		flex: auto;
	}
}
